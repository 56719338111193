import * as icons from '@radix-ui/react-icons';
import { CheckIcon, CopyIcon, ViewGridIcon } from '@radix-ui/react-icons';
import * as scaleUIRadixComponents from '@scale/scaleui-radix';
import { Box, IconButton, ScrollArea, Theme, Tooltip } from '@scale/scaleui-radix';
import {
  faChessClock,
  faChessQueenPiece,
  faSearch,
  faGamepad,
  faBookmark,
  faEllipsis,
} from '@fortawesome/pro-regular-svg-icons';
import copy from 'copy-to-clipboard';
import { toHtml as hastToHtml } from 'hast-util-to-html';
import { compressToEncodedURIComponent } from 'lz-string';
import rangeParser from 'parse-numeric-range';
import * as React from 'react';
import { highlight, register } from 'refractor/core';
import bash from 'refractor/lang/bash';
import css from 'refractor/lang/css';
import diff from 'refractor/lang/diff';
import js from 'refractor/lang/javascript';
import jsx from 'refractor/lang/jsx';
import highlightWord from '@utils/rehype-highlight-word.mjs';
import highlightLine from '@utils/rehype-highlight-line.mjs';
import { classNames } from '@utils/classNames';
import styles from './CodeBlock.module.css';
import { LiveCode } from './LiveCode';
import { RightClickZone } from './RightClickZone';
import { DecorativeBox, ThemesVolumeControlExample } from './ThemesDocsAssets';
import { DATA_TABLE_USERS_COLUMNS, getDataTableUsers } from './mocks';

const DATA_TABLE_USERS = getDataTableUsers(35);

type RootProps = React.ComponentPropsWithoutRef<typeof Box>;
const Root = React.forwardRef<HTMLDivElement, RootProps>(function Root(
  { className, ...props },
  forwardedRef
) {
  return (
    <Box className={classNames(styles.CodeBlockRoot, className)} ref={forwardedRef} {...props} />
  );
});
Root.displayName = 'CodeBlock.Root';

type LivePreviewProps = React.ComponentPropsWithoutRef<typeof Box> & {
  code: string;
  scroll?: boolean;
};

const scope = {
  ...scaleUIRadixComponents,
  ...icons,
  ThemesVolumeControlExample,
  DecorativeBox,
  RightClickZone,
  MOCKS: {
    DATA_TABLE_USERS_COLUMNS,
    DATA_TABLE_USERS,
  },
  faChessClock,
  faChessQueenPiece,
  faSearch,
  faGamepad,
  faBookmark,
  faEllipsis,
};

const LivePreview = React.forwardRef<HTMLDivElement, LivePreviewProps>(function LivePreview(
  { className, code, scroll = false, ...props },
  forwardedRef
) {
  // We want to avoid the automatic scroll in Table examples to show what happens when the table is scrollable
  const isTableExampleCode = code.startsWith('<Table.Root') || code.startsWith('<DataTable.Root');

  return (
    <Theme asChild>
      <Box
        className={classNames(styles.CodeBlockLivePreview, className)}
        ref={forwardedRef}
        {...props}
      >
        {isTableExampleCode ? (
          <div className={styles.CodeBlockLivePreviewInner} data-scroll={scroll}>
            <LiveCode code={code} scope={scope} />
          </div>
        ) : (
          <ScrollArea>
            <div className={styles.CodeBlockLivePreviewInner} data-scroll={scroll}>
              <LiveCode code={code} scope={scope} />
            </div>
          </ScrollArea>
        )}
      </Box>
    </Theme>
  );
});

LivePreview.displayName = 'CodeBlock.LivePreview';

type HeaderProps = React.ComponentPropsWithoutRef<typeof Box>;

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(function Header(
  { className, ...props },
  forwardedRef
) {
  return (
    <Box className={classNames(styles.CodeBlockHeader, className)} ref={forwardedRef} {...props} />
  );
});

Header.displayName = 'CodeBlock.Header';

type ContentProps = React.ComponentPropsWithoutRef<typeof Box>;

const Content = React.forwardRef<HTMLDivElement, ContentProps>(function Content(
  { className, ...props },
  forwardedRef
) {
  return (
    <Box
      className={classNames(styles.CodeBlockContent, className)}
      data-code-block-content
      ref={forwardedRef}
      {...props}
    />
  );
});

Content.displayName = 'CodeBlock.Content';

type PreProps = {
  overflow?: 'scroll' | 'hidden';
} & React.ComponentPropsWithoutRef<'pre'>;
const Pre = React.forwardRef<HTMLPreElement, PreProps>(function Pre(
  { className, children, overflow = 'scroll', ...props },
  forwardedRef
) {
  const pre = (
    <pre className={classNames(styles.CodeBlockPre, className)} ref={forwardedRef} {...props}>
      {children}
    </pre>
  );

  if (overflow === 'hidden') {
    return pre;
  }

  return <ScrollArea>{pre}</ScrollArea>;
});
Pre.displayName = 'CodeBlock.Pre';

type CodeProps = {
  children: string;
  language: 'js' | 'jsx' | 'bash' | 'css' | 'diff';
  lines?: string;
  invertLineHighlight?: boolean;
} & React.ComponentPropsWithoutRef<'code'>;
const Code = React.forwardRef<HTMLElement, CodeProps>(function Code(
  { className, children, invertLineHighlight = false, language, lines = '0', ...props },
  forwardedRef
) {
  let root = highlight(children, language);

  root = highlightLine(root, rangeParser(lines));
  const content = highlightWord(root);
  const result = hastToHtml(content);

  return (
    <code
      className={classNames(styles.CodeBlockCode, className)}
      dangerouslySetInnerHTML={{ __html: result }}
      data-invert-line-highlight={invertLineHighlight}
      ref={forwardedRef}
      suppressHydrationWarning
      {...props}
    />
  );
});
Code.displayName = 'CodeBlock.Code';

type CopyButtonProps = React.ComponentPropsWithoutRef<typeof IconButton>;
const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(
  ({ className, ...props }, forwardedRef) => {
    const [hasCopied, setHasCopied] = React.useState(false);

    React.useEffect(() => {
      if (hasCopied) {
        setTimeout(() => {
          setHasCopied(false);
        }, 1500);
      }
    }, [hasCopied]);

    return (
      <Tooltip content="Copy code to clipboard">
        <IconButton
          aria-label="Copy code to clipboard"
          ref={forwardedRef}
          {...props}
          className={classNames(styles.CodeBlockCopyButton, className)}
          color="gray"
          onClick={(event) => {
            const value =
              event.currentTarget.closest(`[data-code-block-content]`)?.querySelector('code')
                ?.textContent ?? '';
            copy(value);
            setHasCopied(true);
          }}
          variant="soft"
        >
          {hasCopied ? <CheckIcon /> : <CopyIcon />}
        </IconButton>
      </Tooltip>
    );
  }
);
CopyButton.displayName = 'CodeBlock.CopyButton';

type OpenInPlayroomButtonProps = React.ComponentPropsWithoutRef<typeof IconButton>;
const OpenInPlayroomButton = React.forwardRef<HTMLButtonElement, OpenInPlayroomButtonProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <Tooltip content="Open code in Playroom">
        <IconButton
          aria-label="Open code in Playroom"
          ref={forwardedRef}
          {...props}
          className={classNames(styles.CodeBlockCopyButton, '!right-[46px]', className)}
          color="gray"
          onClick={(event) => {
            const code =
              event.currentTarget.closest(`[data-code-block-content]`)?.querySelector('code')
                ?.textContent ?? '';

            const compressedCode = compressToEncodedURIComponent(
              JSON.stringify({
                code: code.startsWith('() => {')
                  ? `{(${code.substring(0, code.length - 2)})()}`
                  : code,
              })
            );

            const url = `/playroom/index.html?code=${compressedCode}`;
            window.open(url, '_blank');
          }}
          variant="soft"
        >
          <ViewGridIcon />
        </IconButton>
      </Tooltip>
    );
  }
);

OpenInPlayroomButton.displayName = 'CodeBlock.OpenInPlayroomButton';

register(js);
register(jsx);
register(bash);
register(css);
register(diff);

const CodeBlock = {
  Root,
  LivePreview,
  Header,
  Content,
  CopyButton,
  OpenInPlayroomButton,
  Pre,
  Code,
};

export { CodeBlock };
