// eslint-disable-next-line camelcase -- This is part of an external library
import { Faker, en, en_US } from '@faker-js/faker';
import { Text, DataTable } from '@scale/scaleui-radix';
import type { ReactNode } from 'react';

const faker = new Faker({
  // eslint-disable-next-line camelcase -- This is part of an external library
  locale: [en_US, en],
});

faker.seed(123);

type UserMock = {
  id: string;
  userName: string;
  email: string;
  rating: number;
  phoneNumber: string;
  admin: boolean;
  randomType: string | number;
  enabled: ReactNode;
  updatedAt: string;
  createdAt: string;
  notes: string;
};

const columnHelper = DataTable.createColumnHelper<UserMock>();

const createMockUserData = (): UserMock => {
  return {
    id: faker.string.uuid(),
    userName: faker.internet.userName(),
    email: faker.internet.email(),
    rating: faker.number.int({ min: 1, max: 10 }),
    randomType:
      faker.number.int({ min: 1, max: 2 }) % 2 ? faker.number.int() : faker.lorem.words(2),
    // This is to show a warning in the console when the column is not a primitive.
    enabled: <Text>{faker.datatype.boolean() ? 'Yes' : 'No'}</Text>,
    admin: faker.datatype.boolean(),
    phoneNumber: faker.phone.number(),
    updatedAt: faker.date.anytime().toString(),
    createdAt: faker.date.anytime().toString(),
    notes: faker.lorem.sentence(),
  };
};

export const getDataTableUsers = (userCount = 10): UserMock[] =>
  faker.helpers.uniqueArray(createMockUserData, userCount);

export const DATA_TABLE_USERS_COLUMNS = [
  columnHelper.accessor('id', {
    id: 'id',
    header: () => (
      <DataTable.CellContentDefault>
        This is the ID of this element in the DB
      </DataTable.CellContentDefault>
    ),
    filterFn: 'includesStringSensitive',
  }),
  columnHelper.accessor('userName', {
    id: 'userName',
    header: () => <DataTable.CellContentDefault>User name</DataTable.CellContentDefault>,
    filterFn: 'includesString',
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: () => <DataTable.CellContentDefault>Email</DataTable.CellContentDefault>,
    filterFn: 'includesString',
  }),
  columnHelper.accessor('enabled', {
    id: 'enabled',
    header: () => <DataTable.CellContentDefault>Enabled</DataTable.CellContentDefault>,
  }),
  columnHelper.accessor('admin', {
    id: 'admin',
    header: () => <DataTable.CellContentDefault>Admin</DataTable.CellContentDefault>,
    cell: (props: any) => (
      <DataTable.CellContentDefault>{props.getValue() ? 'Yes' : 'No'}</DataTable.CellContentDefault>
    ),
  }),
  columnHelper.accessor('rating', {
    id: 'rating',
    header: () => <DataTable.CellContentDefault>Rating</DataTable.CellContentDefault>,
    filterFn: 'inNumberRange',
    cell: (props: any) => {
      const rating = props.getValue();
      return (
        <DataTable.CellContentDefault
          textProps={{
            color: rating >= 6 ? 'warning' : rating >= 8 ? 'success' : 'error',
          }}
        >
          {rating}
        </DataTable.CellContentDefault>
      );
    },
  }),
  columnHelper.accessor('randomType', {
    id: 'randomType',
    header: () => <DataTable.CellContentDefault>Random Type</DataTable.CellContentDefault>,
  }),
  columnHelper.accessor('phoneNumber', {
    id: 'phoneNumber',
    header: () => <DataTable.CellContentDefault>Phone Number</DataTable.CellContentDefault>,
  }),
  columnHelper.accessor('updatedAt', {
    id: 'updatedAt',
    header: () => <DataTable.CellContentDefault>Updated At</DataTable.CellContentDefault>,
    cell: (props: any) => {
      const d = new Date(props.getValue());
      return (
        <Text className="block" truncate>
          {d.toLocaleDateString('en-us', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
      );
    },
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => <DataTable.CellContentDefault>Created At</DataTable.CellContentDefault>,
    cell: (props: any) => {
      const d = new Date(props.getValue());
      return (
        <Text className="block" truncate>
          {d.toLocaleDateString('en-us', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
      );
    },
  }),
  columnHelper.accessor('notes', {
    id: 'notes',
    header: () => <DataTable.CellContentDefault>Notes</DataTable.CellContentDefault>,
  }),
];
