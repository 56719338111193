import * as React from 'react';
import { Flex, Text, Box } from '@scale/scaleui-radix';

export function ColorUsageRange({
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof Flex>) {
  return (
    <Flex direction="column" mb="2" {...props}>
      <Text align="center" color="gray" mb="3" size="1">
        {children}
      </Text>
      <Box
        style={{
          height: 1,
          backgroundImage:
            'linear-gradient(to right, transparent, var(--gray-a8) 30%, var(--gray-a8) 70%, transparent)',
        }}
      />
    </Flex>
  );
}
