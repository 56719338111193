import { Flex, Grid, Text } from '@scale/scaleui-radix';
import * as React from 'react';

type RightClickZoneProps = React.ComponentPropsWithoutRef<typeof Grid> & {
  title: string;
};

export function RightClickZone({ title, style, ...props }: RightClickZoneProps) {
  return <Grid
    {...props}
    style={{
      height: 100,
      placeItems: 'center',
      border: '1px dashed var(--accent-a6)',
      backgroundColor: 'var(--accent-a2)',
      borderRadius: 'var(--radius-3)',
      cursor: 'default',
      ...style,
    }}
  >
    <Flex align="center" direction="column">
      {title ? <Text size="2" weight="medium">
          {title}
        </Text> : null}
      <Text color="gray" size={title ? '2' : undefined}>
        Right-click here
      </Text>
    </Flex>
  </Grid>
}
