import { Flex } from '@scale/scaleui-radix';

export function Marker(props: React.ComponentPropsWithoutRef<typeof Flex>) {
  return <Flex
    align="center"
    height="16px"
    justify="center"
    width="16px"
    {...props}
    style={{
      color: 'var(--teal-11)',
      backgroundColor: 'var(--teal-a4)',
      borderRadius: '100%',
      ...props.style,
    }}
  />
}
